.programs {
  width: 100%;
  background-color: var(--background-secondary-color);
  padding: 150px 0px 100px 0px;
}
.programs__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.programs__title {
  margin: 0px;
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 50px;
  text-align: center;
  color: var(--title-color);
}
.programs__nav {
  margin: 50px 0px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.programs__menu {
  display: flex;
  padding: 0px;
  list-style-type: none;
  margin: 0px;
}
.programs__menu-item {
  margin-right: 20px;
}
.programs__menu-item:last-of-type {
  margin-right: 0px;
}
.programs__menu-button {
  background-color: var(--secondary-color);
  border: none;
  outline: none;
  color: var(--white-color);
  box-sizing: border-box;
  border-radius: 50px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 2px;
  padding: 13px 35px;
  transition: .3s ease-in-out;
}
.programs__menu-button:hover {
  cursor: pointer;
  opacity: .7;
}
.programs__menu-button_type_active {
  background-color: var(--main-color);
  color: var(--white-color);
}
.programs__search {
  background: #FFFFFF;
  box-sizing: border-box;
  backdrop-filter: blur(8px);
  border-radius: 100px;
  display: flex;
  justify-content: space-between;
}
.programs__search-input {
  background-color: #fff;
  border-radius: 100px;
  border: none;
  padding: 10px 50px 10px 20px;
  outline: none;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.15px;
  color: #1A1B22;
  border: 2px solid #D1D2D6;
}
.programs__search-input:focus {
  border: 2px solid #efe4e3;
}
.programs__search-button {
  margin: 0px 0px 0px -45px;
  width: 45px;
  height: 45px;
  border-radius: 100px;
  background-image: url('../../images/search.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  border: none;
  outline: none;
  cursor: pointer;
  transition: .3s;
}
.programs__search-button:hover {
  opacity: .7;
}
.programs__item-container {
  margin: 0px;
  padding: 70px 0px 50px;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.programs__item {
  background-color: var(--white-color);
  border: 1px solid var(--border-card-color);
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
  border-radius: 10px;
  transition: .3s ease-in-out;
  width: 600px;
  margin-bottom: 30px;
  position: relative;
}
.programs__discount-list {
  display: flex;
  flex-direction: column;
  padding: 0px;
  margin: 0px;
  list-style-type: none;
  position: absolute;
  top: 100px;
  right: -5px;
}
.programs__discount-item {
  margin: 0px 0px 5px 0px;
  background-color: var(--main-color);
  color: var(--white-color);
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
  box-sizing: border-box;
  border: none;
  padding: 3px 10px;
  border-radius: 3px;
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  font-style: normal;
  text-align: center;
  outline: none;
  position: relative;
  pointer-events: none;
  user-select: none;
  width: 60px;
}
.programs__discount-item:last-of-type {
  margin: 0px;
}
.programs__video-link {
  display: flex;
  margin: 0px;
  position: absolute;
  top: -5px;
  right: -5px;
  width: 60px;
  height: 24px;
  background-color: var(--secondary-color);
  background-image: url('../../images/video.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
  box-sizing: border-box;
  border: none;
  padding: 3px 10px;
  border-radius: 3px;
  outline: none;
  transition: .3s ease-in-out;
}
.programs__video-link:hover {
  cursor: pointer;
  transform: scale(1.05);
}
.programs__item:hover {
  background-color: var(--secondary-color);
  border: 1px solid var(--secondary-color);
}
.programs__item:hover .programs__item-name {
  color: var(--white-color);
}
.programs__item:hover .programs__item-tag {
  color: var(--white-color);
  background-color: var(--main-color);
}
.programs__item:hover .programs__item-form {
  color: var(--white-color);
}
.programs__item:hover .programs__item-time {
  color: var(--white-color);
}
.programs__item:hover .programs__video-link {
  background-color: var(--main-color);
}
.programs__item-top {
  margin: 0px;
  display: flex;
  justify-content: space-between;
  padding: 20px 40px;
  min-height: 220px;
  position: relative;
}
.programs__item-description {
  display: flex;
  flex-direction: column;
}
.programs__item-tag {
  margin: 0px;
  padding: 3px 5px;
  color: var(--main-color);
  border-radius: 3px;
  font-size: 16px;
  line-height: 20px;
  pointer-events: none;
  user-select: none; 
  width: max-content;
  background-color: rgba(236, 110, 102, 0.1);
}
.programs__item-name {
  margin: 20px 0px 0px 0px;
  font-size: 26px;
  line-height: 1.15;
  font-weight: bold;
  max-width: 250px;
  color: var(--title-color);
}
.programs__img {
  width: 200px;
  height: 200px;
  margin-right: 20px;
  transition: .3s ease-in-out;
}
.programs__img_type_complex {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url('../../images/programs/complex.png');
}
.programs__item:hover .programs__img_type_complex {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url('../../images/programs/complex-hover.png');
}
.programs__img_type_inform {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url('../../images/programs/inform.png');
}
.programs__item:hover .programs__img_type_inform {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url('../../images/programs/inform-hover.png');
}
.programs__img_type_foresight {
  background-repeat: no-repeat;
  background-size: 120%;
  background-position: center;
  background-image: url('../../images/programs/foresight.png');
}
.programs__item:hover .programs__img_type_foresight {
  background-repeat: no-repeat;
  background-size: 120%;
  background-position: center;
  background-image: url('../../images/programs/foresight-hover.png');
}
.programs__img_type_organization {
  background-repeat: no-repeat;
  background-size: 120%;
  background-position: center;
  background-image: url('../../images/programs/organization.png');
}
.programs__item:hover .programs__img_type_organization {
  background-repeat: no-repeat;
  background-size: 120%;
  background-position: center;
  background-image: url('../../images/programs/organization-hover.png');
}
.programs__img_type_project {
  background-repeat: no-repeat;
  background-size: 120%;
  background-position: center;
  background-image: url('../../images/programs/project.png');
}
.programs__item:hover .programs__img_type_project {
  background-repeat: no-repeat;
  background-size: 120%;
  background-position: center;
  background-image: url('../../images/programs/project-hover.png');
}
.programs__img_type_analytics {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url('../../images/programs/analytics.png');
}
.programs__item:hover .programs__img_type_analytics {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url('../../images/programs/analytics-hover.png');
}
.programs__img_type_innovation {
  background-repeat: no-repeat;
  background-size: 120%;
  background-position: center;
  background-image: url('../../images/programs/innovation.png');
}
.programs__item:hover .programs__img_type_innovation {
  background-repeat: no-repeat;
  background-size: 120%;
  background-position: center;
  background-image: url('../../images/programs/innovation-hover.png');
}
.programs__img_type_finance {
  background-repeat: no-repeat;
  background-size: 120%;
  background-position: center;
  background-image: url('../../images/programs/finance.png');
}
.programs__item:hover .programs__img_type_finance  {
  background-repeat: no-repeat;
  background-size: 120%;
  background-position: center;
  background-image: url('../../images/programs/finance-hover.png');
}
.programs__img_type_development {
  background-repeat: no-repeat;
  background-size: 120%;
  background-position: center;
  background-image: url('../../images/programs/development.png');
}
.programs__item:hover .programs__img_type_development  {
  background-repeat: no-repeat;
  background-size: 120%;
  background-position: center;
  background-image: url('../../images/programs/development-hover.png');
}
.programs__img_type_audit {
  background-repeat: no-repeat;
  background-size: 110%;
  background-position: center;
  background-image: url('../../images/programs/audit.png');
}
.programs__item:hover .programs__img_type_audit  {
  background-repeat: no-repeat;
  background-size: 110%;
  background-position: center;
  background-image: url('../../images/programs/audit-hover.png');
}
.programs__img_type_personal {
  background-repeat: no-repeat;
  background-size: 120%;
  background-position: center;
  background-image: url('../../images/programs/personal.png');
}
.programs__item:hover .programs__img_type_personal  {
  background-repeat: no-repeat;
  background-size: 120%;
  background-position: center;
  background-image: url('../../images/programs/personal-hover.png');
}
.programs__img_type_work {
  background-repeat: no-repeat;
  background-size: 90%;
  background-position: center;
  background-image: url('../../images/programs/work.png');
}
.programs__item:hover .programs__img_type_work  {
  background-repeat: no-repeat;
  background-size: 90%;
  background-position: center;
  background-image: url('../../images/programs/work-hover.png');
}
.programs__img_type_design {
  background-repeat: no-repeat;
  background-size: 90%;
  background-position: center;
  background-image: url('../../images/programs/design.png');
}
.programs__item:hover .programs__img_type_design {
  background-repeat: no-repeat;
  background-size: 90%;
  background-position: center;
  background-image: url('../../images/programs/design-hover.png');
}
.programs__img_type_estate {
  background-repeat: no-repeat;
  background-size: 120%;
  background-position: center;
  background-image: url('../../images/programs/estate.png');
}
.programs__item:hover .programs__img_type_estate {
  background-repeat: no-repeat;
  background-size: 120%;
  background-position: center;
  background-image: url('../../images/programs/estate-hover.png');
}
.programs__img_type_infrastructure {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url('../../images/programs/infrastructure.png');
}
.programs__item:hover .programs__img_type_infrastructure {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url('../../images/programs/infrastructure-hover.png');
}
.programs__img_type_arctic {
  background-repeat: no-repeat;
  background-size: 120%;
  background-position: center;
  background-image: url('../../images/programs/arctic.png');
}
.programs__item:hover .programs__img_type_arctic {
  background-repeat: no-repeat;
  background-size: 120%;
  background-position: center;
  background-image: url('../../images/programs/arctic-hover.png');
}
.programs__img_type_social {
  background-repeat: no-repeat;
  background-size: 120%;
  background-position: center;
  background-image: url('../../images/programs/social.png');
}
.programs__item:hover .programs__img_type_social {
  background-repeat: no-repeat;
  background-size: 120%;
  background-position: center;
  background-image: url('../../images/programs/social-hover.png');
}
.programs__img_type_digital {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url('../../images/programs/digital.png');
}
.programs__item:hover .programs__img_type_digital {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url('../../images/programs/digital-hover.png');
}
.programs__item-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #E5E6ED;
  padding: 10px 40px;
  max-height: 41px;
}
.programs__description-bottom {
  margin: 0px;
  display: flex;
}
.programs__item-form {
  padding-right: 10px;
  font-size: 16px;
  line-height: 20px;
  margin: 0px;
  border-right: 1px solid #E5E6ED;
}
.programs__item-time {
  margin-left: 10px;
  font-size: 16px;
  line-height: 20px;
}
.programs__button-bottom {
  background-color: rgba(64, 123, 255, .1);
  color: var(--secondary-color);
  box-sizing: border-box;
  border: none;
  padding: 3px 10px;
  border-radius: 3px;
  font-size: 16px;
  line-height: 20px;
  font-style: normal;
  text-align: center;
  outline: none;
  transition: .3s ease-in-out;
  position: relative;
}
.programs__item:hover .programs__button-bottom {
  cursor: pointer;
  background-color: var(--main-color);
  color: var(--white-color);
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
}
.programs__button-bottom:hover {
  transform: scale(1.05);
}
.programs__btn-add {
  margin: 0px auto 100px;
  max-width: 300px;
  background-color: transparent;
  border: 1px solid var(--secondary-color);
  color: var(--secondary-color);
  box-sizing: border-box;
  border-radius: 50px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 2px;
  padding: 13px 45px;
  outline: none;
  transition: .3s ease-in-out;
}
.programs__btn-add:hover {
  cursor: pointer;
  background-color: var(--secondary-color);
  color: var(--white-color);
}

@media all and (max-width: 1400px) {
  .programs__item {
    width: 580px;
  }
}

@media all and (max-width: 1250px) {
  .programs {
    padding: 100px 0px 100px 0px;
  }
  .programs__title {
    font-size: 28px;
    line-height: 1.3;
  }
  .programs__menu-button {
    font-size: 14px;
    line-height: 1.15;
    padding: 10px 18px;
  }
  .programs__item-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .programs__item {
    width: 460px;
    margin-bottom: 30px;
  }
  .programs__item-name {
    font-size: 24px;
    line-height: 1.15;
  }
  .programs__img {
    width: 180px;
    height: 180px;
    margin-right: -20px;
  }
  .programs__item-top {
    min-height: 180px;
  }
}

@media all and (max-width: 1000px) {
  .programs__nav {
    flex-direction: column;
  }
  .programs__search {
    margin-top: 20px;
  }
  .programs__item-container {
    justify-content: center;
    margin: 0px;
    padding: 70px 0px 20px;
  }
  .programs__btn-add {
    margin: 0px auto 50px;
  }
  .programs__item-top {
    padding: 20px 20px;
  }
  .programs__item-bottom {
    padding: 10px 20px;
  }
  .programs__img {
    margin-right: 0px;
  }
}

@media all and (max-width: 760px) {
  .programs__title {
    font-size: 24px;
    line-height: 1.3;
  }
  .programs__item-container {
    padding: 40px 0px 20px;
  }
  .programs__item {
    width: 288px;
  }
  .programs__item-top {
    flex-direction: column;
    padding: 20px 20px 0px 20px;
  }
  .programs__item-name {
    margin-top: 15px;
    font-size: 18px;
    line-height: 1.15;
  }
  .programs__img {
    width: 180px;
    height: 180px;
    margin: 0px auto;
  }
  .programs__item-bottom {
    flex-direction: column;
    max-height: min-content;
  }
  .programs__item-form {
    padding-right: 10px;
    font-size: 14px;
    line-height: 18px;
  }
  .programs__item-time {
    margin-left: 10px;
    font-size: 14px;
    line-height: 18px;
  }
  .programs__button-bottom {
    margin: 10px 0px 0px 0px;
  }
  .programs__item-tag {
    font-size: 14px;
    line-height: 18px;
  }
  .programs__nav {
    margin: 20px 0px 0px 0px;
  }
  .programs__menu {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  .programs__menu-item {
    margin: 0px 5px 10px 5px;
  }
  .programs__search {
    margin-top: 10px;
  }
}
