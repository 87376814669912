.detail {
  margin: 10vh auto 0px;
  min-height: 114px;
  max-height: 85vh;
  position: relative;
  display: flex;
  flex-direction: column;
}
.detail__popup {
  overflow-y: auto;
  padding: 34px 60px 28px;
  border-radius: 16px;
  background-color: var(--white-color);
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
}
.detail__popup::-webkit-scrollbar {
  width: 10px;
  border-radius: 16px;
}
.detail__popup::-webkit-scrollbar-thumb {
  background-color: var(--background-third-color);
  outline: none;
  border-radius: 16px;
}
.detail__close-button {
  position: absolute;
  padding: 0;
  right: -45px;
  top: -45px;
  width: 40px;
  height: 40px;
  border: none;
  box-sizing: border-box;
  background-color: transparent;
  background-image: url(../../images/close-button.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  -webkit-background-size: contain;        
  -moz-background-size: contain;          
  -o-background-size: contain;        
  cursor: pointer;
  transition: opacity .3s ease-in-out;
  outline: none;
  z-index: -1;
}
.detail__close-button:hover {
  opacity: .7;
}
.detail__container {
  margin: 0px;
  display: flex;
  justify-content: space-between;
}
.detail__description {
  display: flex;
  flex-direction: column;
  max-width: 700px;
}
.detail__tag {
  pointer-events: none;
  user-select: none;
  margin: 0px;
  padding: 6px 10px;
  color: var(--white-color);
  border-radius: 6px;
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  letter-spacing: 1.2px;
  background-color: var(--main-color);
  width: max-content;
}
.detail__specialization {
  margin: 20px 0px 0px 0px;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.15;
  color: var(--title-color);
}
.detail__title {
  margin: 8px 0px 50px 0px;
  font-size: 52px;
  font-weight: bold;
  line-height: 1.05;
  color: var(--title-color);
}
.detail__title_type_small {
  max-width: 400px;
}
.detail__title_type_large {
  max-width: 700px;
}
.detail__info-container {
  padding: 0px;
  margin: auto 0px 0px 0px;
  list-style-type: none;
  display: flex;
}
.detail__info {
  padding-left: 20px;
  margin: 0px 50px 0px 0px;
  border-left: 2px solid var(--secondary-color);
  display: flex;
  flex-direction: column;
}
.detail__info:last-of-type {
  margin: 0px;
}
.detail__info-item {
  font-size: 18px;
  font-weight: normal;
  line-height: 1.15;
  color: var(--secondary-color);
}
.detail__info-item_weight_bold {
  font-weight: bold;
}
.detail__form {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.detail__form-title {
  margin: 20px 0px 15px 0px;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.15;
}
.detail__form-input {
  margin: 0px 0px 20px 0px;
  width: calc(100% - 60px);
  justify-content: space-between;
  padding: 15px 30px;
  border-radius: 100px;
  border: 2px solid var(--background-third-color);
  outline: none;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 1.15px;
  color: var(--title-color);
  letter-spacing: 1.2px;
  transition: all .3s ease-in-out;
}
.detail__form-input:focus {
  border: 2px solid var(--secondary-color);
}
.detail__form-input::placeholder {
  color: var(--subtitle-color);
  font-size: .8em;
  letter-spacing: .5px;
}
.detail__form-input:last-of-type {
  margin: 0px;
}
.detail__form-submit {
  margin: 15px auto 0px;
  max-width: 350px;
  padding: 17.5px 77.5px;
  position: relative;
  z-index: 3;
}
.detail__form-submit:hover {
  cursor: pointer;
  opacity: .7;
}
.detail__program-description {
  margin: 30px 0px 0px 0px;
  font-size: 18px;
  line-height: 1.3;
  color: var(--title-color);
}
.detail__program-description_weight_bold {
  font-weight: bold;
  text-transform: initial;
}
.detail__course-list {
  margin: 20px 0px 0px 0px;
  padding: 0px;
  list-style-type: none;
}
.detail__course-item {
  margin: 0px 0px 10px 0px;
}
.detail__course-item:last-of-type {
  margin: 0px;
}
.detail__discount {
  margin: 20px 0px 30px 0px;
}
.detail__discount-description {
  margin: 0px;
  font-size: 18px;
  font-weight: normal;
  line-height: 1.3;
  color: var(--title-color);
}
.detail__discount-description_weight_bold {
  font-weight: bold;
  text-transform: initial;
}
.detail__discount-list {
  display: flex;
  flex-direction: column;
  padding: 0px;
  margin: 10px 0px 0px 0px;
  list-style-type: none;
}
.detail__discount-item {
  margin: 0px 0px 10px 0px;
  display: flex;
  align-items: center;
}
.detail__discount-item:last-of-type {
  margin: 0px;
}
.detail__discount-percent {
  margin: 0px 7px 0px 0px;
  background-color: var(--main-color);
  color: var(--white-color);
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
  box-sizing: border-box;
  border: none;
  padding: 3px 15px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  font-style: normal;
  text-align: center;
  outline: none;
  position: relative;
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
}
.detail__discount-text {
  margin: 0px;
  font-size: 18px;
  font-weight: normal;
  line-height: 1.3;
  color: var(--title-color);
}

@media all and (max-width: 1400px) {
  .detail__close-button {
    position: absolute;
    padding: 0;
    right: 0px;
    top: -50px;
  }
}

@media all and (max-width: 1250px) {
  .detail__popup {
    overflow-y: auto;
    padding: 28px 40px 20px;
    border-radius: 16px;
    background-color: var(--white-color);
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  }
  .detail__description {
    max-width: 600px;
  }
  .detail__tag {
    font-size: 14px;
    line-height: 18px;
  }
  .detail__specialization {
    margin: 20px 0px 0px 0px;
    font-size: 20px;
  }
  .detail__title {
    margin: 8px 0px 30px 0px;
    font-size: 40px;
    line-height: 1.05;
  }
  .detail__title_type_small {
    max-width: 300px;
  }
  .detail__title_type_large {
    max-width: 600px;
  }
  .detail__info {
    padding-left: 7px;
    margin: 0px 50px 0px 0px;
  }
  .detail__info-item {
    font-size: 14px;
    line-height: 1.15;
  }
  .detail__program-description {
    font-size: 16px;
    line-height: 1.3;
  }
  .detail__discount-text {
    font-size: 16px;
    line-height: 1.3;
  }
  .detail__discount-description {
    font-size: 16px;
    line-height: 1.3;
  }
  .detail__form-title {
    font-size: 20px;
  }
  .detail__form-input {
    margin: 0px 0px 20px 0px;
    width: calc(100% - 60px);
    justify-content: space-between;
    padding: 13px 28px;
    font-size: 16px;
    line-height: 1.15px;
  }
  .detail__form-submit {
    margin: 15px auto 0px;
    max-width: 290px;
    padding: 12px 45px;
    position: relative;
    z-index: 3;
  }
  .detail__close-button {
    width: 35px;
    height: 35px;
  }
}

@media all and (max-width: 1000px) {
  .detail__popup {
    padding: 20px 20px 14px;
  }
  .detail__title {
    margin: 8px 0px 30px 0px;
    font-size: 28px;
    line-height: 1.05;
  }
  .detail__title_type_small {
    max-width: 200px;
  }
  .detail__title_type_large {
    max-width: 400px;
  }
  .detail__info {
    margin: 0px 20px 0px 0px;
  }
  .detail__info-item {
    font-size: 12px;
    line-height: 1.15;
  }
  .detail__specialization {
    font-size: 18px;
  }
  .detail__form-title {
    font-size: 18px;
  }
  .detail__form-input {
    margin: 0px 0px 15px 0px;
    width: calc(100% - 80px);
    justify-content: space-between;
    padding: 8px 20px;
    font-size: 14px;
    line-height: 1.15px;
  }
  .detail__form-submit {
    margin: 15px auto 0px;
    max-width: 290px;
    padding: 8px 20px;
    font-size: 14px;
  }
  .detail__close-button {
    width: 30px;
    height: 30px;
    top: -35px;
  }
  .detail__program-description {
    font-size: 14px;
  }
  .detail__discount-text {
    font-size: 14px;
  }
  .detail__discount-description {
    font-size: 14px;
  }
  .detail__discount-percent {
    padding: 3px 10px;
  }
}

@media all and (max-width: 760px) {
  .detail {
    margin: 3vh auto 0px;
    max-height: 90vh;
  }
  .detail__popup {
    padding: 20px 16px 14px
  }
  .detail__form {
    display: none;
  }
  .detail__specialization {
    font-size: 16px;
  }
  .detail__title {
    margin: 8px 0px 15px;
    font-size: 24px;
  }
  .detail__info-container {
    flex-direction: column;
  }
  .detail__info {
    margin-bottom: 10px;
    margin-right: 0px;
  }
  .detail__info-item {
    font-size: 14px;
  }
  .detail__title_type_small {
    max-width: max-content;
  }
  .detail__title_type_large {
    max-width: max-content;
  }
  .detail__program-description {
    margin-top: 15px;
  }
  .detail__button {
    margin: 15px 0px 20px 0px;
    width: 100%;
  }
  .detail__close-button {
    right: 10px;
    top: 15px;
    z-index: 20;
  }
}