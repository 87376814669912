.header {
  width: 100%;
  padding: 15px 0px;
  border-bottom: 1px solid #EEEFF2;
  position: fixed;
  background-color: var(--background-main-color);
  z-index: 100;
}
.header__nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header__logo {
  height: 60px;
  object-fit: contain;
  object-position: center;
  pointer-events: none;
}
.header__links {
  display: flex;
  align-items: center;
  list-style-type: none;
  padding: 0px;
}
.header__link {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  color: var(--subtitle-color);
  margin-right: 35px;
  transition: .3s ease-in-out;
}
.header__link:hover {
  cursor: pointer;
  color: var(--main-color);
}
.header__link:last-of-type {
  margin-right: 0px;
}
.header__phone {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  color: var(--main-color);
  letter-spacing: 2px;
  text-decoration: none;
}
.header__button {
  background-color: transparent;
  border: 1px solid var(--secondary-color);
  color: var(--secondary-color);
  box-sizing: border-box;
  border-radius: 50px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 2px;
  padding: 13px 45px;
  outline: none;
  transition: .3s ease-in-out;
  text-decoration: none;
}
.header__button:hover {
  cursor: pointer;
  background-color: var(--secondary-color);
  color: var(--white-color);
}
.header__hamburger-button {
  width: 25px;
  height: 25px;
  border: none;
  padding: 0;
  transition: .3s ease-in-out;
  outline: none;
  background-image: url('../../images/secondary-menu.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: inherit;
}
.header__hamburger-button:hover {
  cursor: pointer;
  opacity: .7;
}
.header__hamburger-button_type_show {
  transform: rotate(90deg);
}
.mobile-menu {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0px;
  top: 0px;
  background-color: var(--secondary-color);
  width: 100%;
  height: 100%;
  transition: all 0.5s ease-in-out;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  z-index: 10;
  position: relative;
}
.mobile-menu_type_hide {
  position: fixed;
  left: -100vw;
  top: 0px;
}
.mobile-menu_type_show {
  position: fixed;
  left: 0px;
  top: 0px;
}
.mobile-menu__container {
  padding: 30px 16px;
  display: flex;
  flex-direction: column;
}
.mobile-menu__top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 15;
}
.mobile-menu__close-button {
  width: 30px;
  height: 30px;
  border: none;
  box-sizing: border-box;
  background-color: transparent;
  background-image: url(../../images/close-button.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  -webkit-background-size: contain;        
  -moz-background-size: contain;          
  -o-background-size: contain;        
  transition: opacity .3s ease-in-out;
  outline: none;
}
.mobile-menu__close-button:hover {
  cursor: pointer;
  opacity: .7;
}
.mobile-menu__main-bar {
  margin: 0px auto;
  display: flex;
  flex-direction: column;
}
.mobile-menu__links {
  list-style-type: none;
  padding: 0px;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}
.mobile__link {
  margin: 0px auto 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
  color: var(--white-color);
  padding: 5px 10px;
  transition: all .3s ease-in-out;
  border-radius: 5px;
  background-color: inherit;
  text-align: center;
  max-width: max-content;
  z-index: 15;
}
.mobile__link:hover {
  background-color: var(--main-color);
  cursor: pointer;
}
.mobile__link-button {
  background-color: var(--main-color);
  border: 1px solid var(--main-color);
  color: var(--white-color);
  box-sizing: border-box;
  border-radius: 50px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 1.3;
  text-align: center;
  letter-spacing: 2px;
  padding: 8px 25px;
  outline: none;
  transition: .3s ease-in-out;
  text-decoration: none;
  z-index: 15;
  margin-bottom: 20px;
  max-width: 250px;
  width: 100%;
  margin: 0px auto 20px;
}
.mobile__link-button:hover {
  cursor: pointer;
  opacity: .7;
}
.mobile-menu__info {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  z-index: 15;
  color: var(--white-color);
  text-align: center;
  margin-bottom: 15px;
  text-decoration: none;
}
.mobile__wave {
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 200px;
  width: 100%;
  overflow: hidden;
}

@media all and (max-width: 1250px) {
  .header__logo {
    height: 60px;
  }
  .header__link {
    margin-right: 15px;
    font-size: 16px;
    line-height: 19px;
  }
  .header__button {
    padding: 10px 25px;
    font-size: 14px;
  }
}

@media all and (max-width: 760px) {
  .header {
    padding: 15px 0px;
  }
  .header__phone {
    font-size: 14px;
    line-height: 1.15;
  }
 }

 @media all and (max-width: 480px) {
  .header__logo {
    height: 40px;
  }
 }

 @media all and (max-width: 360px) {
  .header__phone {
    display: none;
  }
 }

