.features {
  margin: 0px auto;
  width: 100%;
  position: relative;
}
.features__container {
  padding-top: 30px;
  width: 100%;
  background-color: var(--secondary-color);
  position: relative;
}
.features__flex-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.features__img {
  margin: 0px 0px -100px 0px;
  width: 550px;
  height: 550px;
  object-fit: contain;
  object-position: center;
  pointer-events: none;
  z-index: 3;
}
.features__info {
  max-width: 500px;
  margin-left: 100px;
  padding-bottom: 50px;
}
.features__title {
  margin: 0px;
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 50px;
  color: var(--white-color);
  z-index: 3;
}
.features__subtitle {
  margin: 20px 0px 0px 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  color: var(--white-color);
  z-index: 3;
}
.features__subtitle_weight_bold {
  font-weight: bold;
}
.wave-features {
  position: absolute;
  bottom: -150px;
  left: 0px;
  height: 150px;
  width: 100%;
  overflow: hidden;
  z-index: 1;
}

@media all and (max-width: 1250px) {
  .features__img {
    margin: 0px 50px -80px 0px;
    width: 400px;
    height: 400px;
  }
  .features__info {
    max-width: 380px;
    margin-left: 100px;
    padding-bottom: 50px;
  }
  .features__title {
    font-size: 24px;
    line-height: 1.4;
  }
  .features__subtitle {
    margin: 20px 0px 0px 0px;
    font-size: 14px;
    line-height: 18px;
  }
  .features__button {
    margin: 20px auto 0px;
  }
}

@media all and (max-width: 1000px) {
  .features__container {
    padding-top: 0px;
  }
  .features__img {
    margin: 0px 0px -100px 0px;
    width: 300px;
    height: 300px;
  }
  .features__info {
    max-width: 360px;
    margin-left: 50px;
    padding-bottom: 0px;
  }
  .features__title {
    font-size: 20px;
    line-height: 1.3;
  }
}

@media all and (max-width: 760px) {
  .features__flex-container {
    flex-direction: column;
  }
  .features__info {
    margin: 0px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
  .features__subtitle {
    margin: 15px 0px 0px 0px;
  }
  .features__img {
    margin: 0px 0px -70px;
  }
}