.testimonials {
  width: 100%;
  background-color: var(--background-secondary-color);
}
.testimonials__title {
  margin: 50px auto 0px;
  max-width: 448px;
  font-weight: bold;
  font-size: 35px;
  line-height: 50px;
  text-align: center;
  color: var(--title-color);
}
.testimonials__subtitle {
  margin: 20px auto 0px;
  max-width: 555px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  color: var(--subtitle-color);
}

@media all and (max-width: 760px) {
  .testimonials__title {
    margin: 20px auto 0px;
    max-width: 248px;
    font-size: 24px;
    line-height: 1.15;
  }
  .testimonials__subtitle {
    margin: 10px auto 0px;
    max-width: 255px;
    font-size: 14px;
    line-height: 18px;
  }
}