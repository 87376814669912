.subscribe {
  width: 100%;
  background-color: var(--secondary-color);
}
.subscribe__container {
  margin: -120px auto 30px;
  width: calc(100% - 140px);
  padding: 58px 70px;
  background-color: var(--white-color);
  border-radius: 10px;
  box-shadow: 0 20px 30px -15px rgb(13 16 37 / 20%);
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.subscribe__info {
  display: flex;
  flex-direction: column;
  max-width: 500px;
}
.subscribe__title {
  margin: 0px;
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 45px;
  color: var(--title-color);
}
.subscribe__subtitle {
  margin: 20px 0px 0px 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  color: var(--subtitle-color);
}
.subscribe__button {
  background-color: var(--main-color);
  color: var(--white-color);
  box-sizing: border-box;
  border: none;
  border-radius: 10px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 2px;
  padding: 17.5px 65px;
  outline: none;
  transition: .3s ease-in-out;
  position: relative;
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
}
.subscribe__button:hover {
  cursor: pointer;
  box-shadow: 0 40px 54px -15px rgba(245, 56, 56, 0.35);
}

@media all and (max-width: 1000px) {
  .subscribe__container {
    margin: -90px auto 30px;
    width: calc(100% - 80px);
    padding: 38px 40px;
  }
  .subscribe__info {
    max-width: 300px;
  }
  .subscribe__title {
    font-size: 24px;
    line-height: 1.3;
  }
  .subscribe__subtitle {
    font-size: 14px;
    line-height: 18px;
  }
  .subscribe__button {
    font-size: 16px;
    line-height: 25px;
    padding: 15px 37.5px;
  }
}

@media all and (max-width: 760px) {
  .subscribe__container {
    margin: -90px auto 30px;
    width: calc(100% - 40px);
    padding: 16px 20px;
    flex-direction: column;
  }
  .subscribe__title {
    font-size: 20px;
    text-align: center;
  }
  .subscribe__subtitle {
    max-width: 220px;
    font-size: 14px;
    line-height: 18px;
    margin: 10px auto 0px;
    text-align: center;
  }
  .subscribe__button {
    margin: 10px auto 0px;
    font-size: 14px;
    line-height: 25px;
    padding: 8px 17.5px;
  }
}