.introduction {
  margin: 0px auto 100px;
  padding-top: 91px;
  width: 100%;
}
.introduction__container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.introduction__description {
  max-width: 700px;
}
.introduction__title {
  margin: 0px;
  font-style: normal;
  font-weight: bold;
  font-size: 50px;
  line-height: 70px;
  color: var(--title-color);
}
.introduction__title_type_bolder {
  font-weight: 900;
}
.introduction__subtitle {
  margin: 20px 0px 0px 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  color: var(--subtitle-color);
}
.introduction__subtitle_type_bolder {
  font-weight: bold;
}
.introduction__button {
  margin: 50px 0px 0px 0px;
  background-color: var(--main-color);
  color: var(--white-color);
  box-sizing: border-box;
  border: none;
  border-radius: 10px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 1.4px;
  padding: 17.5px 77.5px;
  outline: none;
  transition: .3s ease-in-out;
  position: relative;
  z-index: 3;
}
.introduction__button:hover {
  cursor: pointer;
  box-shadow: 0 40px 54px -15px rgba(245, 56, 56, 0.35);
}
.introduction__img {
  width: 550px;
  height: 550px;
  object-position: center;
  object-fit: contain;
  pointer-events: none;
  user-select: none;
  overflow: hidden;
}

@media all and (max-width: 1400px) {
  .introduction__img {
    width: 450px;
    height: 450px;
  }
  .introduction {
    margin: 0px auto 75px;
  }
  .introduction__button {
    margin: 40px 0px 0px 0px;
    font-size: 16px;
    line-height: 1.15;
    padding: 17.5px 50px;
  }
}

@media all and (max-width: 1250px) {
  .introduction__description {
    max-width: 500px;
  }
  .introduction__title {
    font-size: 34px;
    line-height: 1.3;
  }
  .introduction__subtitle {
    font-size: 14px;
    line-height: 1.15;
  }
  .introduction__img {
    width: 350px;
    height: 350px;
    margin-right: 30px;
  }
  .introduction__button {
    margin: 30px 0px 0px 0px;
    padding: 12.5px 40px;
  }
}

@media all and (max-width: 1000px) {
  .introduction__description {
    max-width: 360px;
  }
  .introduction__title {
    font-size: 28px;
  }
  .introduction__subtitle {
    font-size: 14px;
  }
  .introduction__img {
    width: 300px;
    height: 300px;
    margin-right: 0px;
  }
  .introduction__button {
    margin: 40px 0px 0px 0px;
    padding: 15px 25px;
    font-size: 16px;
    line-height: 20px;
  }
}

@media all and (max-width: 760px) {
  .introduction {
    margin: 0px auto 20px;
  }
  .introduction__container {
    flex-direction: column-reverse;
  }
  .introduction__title {
    font-size: 24px;
  }
  .introduction__subtitle {
    max-width: 260px;
  }
  .introduction__description {
    display: flex;
    flex-direction: column;
  }
  .introduction__img {
    width: 250px;
    height: 250px;
    margin-bottom: 20px;
  }
}

