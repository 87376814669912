@keyframes spin {
	100% {
		transform: rotate(360deg);
	}
}

.preloader {
  width: 100%;
	margin: 0;
	min-height: 282px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.preloader__circle {
	width:  74px;
	height: 74px;
	border: 1px solid #222;
	border-bottom-color: #888;
	border-radius: 50%;
  animation: spin .75s infinite linear;
  margin-bottom: 24px;
}
.preloader__caption {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: var(--subtitle-color);
}