.slider {
  margin: 60px auto 100px;
  max-width: 1280px;
}
.slider__item {
  width: 400px;
  height: 350px;
  border: 2px solid var(--border-card-color);
  box-sizing: border-box;
  border-radius: 10px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  outline: none;
}
.slider__person {
  display: flex;
  align-items: center;
}
.slider__person-img {
  width: 50px;
  height: 50px;
  object-fit: contain;
  object-position: center;
  margin-right: 21px;
  border-radius: 50%;
  pointer-events: none;
  user-select: none;
}
.slider__person-info {
  display: flex;
  flex-direction: column;
  margin-right: 5px;
}
.slider__person-name {
  margin: 0px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 1.15;
  color: var(--title-color);
}
.slider__person-town {
  margin: 5px 0px 0px 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.15;
  color: var(--subtitle-color);
}
.slider__person-rating {
  margin: 0px 0px 0px auto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 30px;
  color: var(--title-color);
  padding-right: 35px;
  position: relative;
}
.slider__person-rating::after {
  content: url('../../images/star.png');
  width: 24px;
  height: 24px;
  position: absolute;
  right: 0px;
  top: 3px;
}
.slider__item-text {
  margin: 20px 0px 0px 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.5;
  color: var(--title-color);
}
.rec.rec-carousel-wrapper {
  position: relative;
}
.rec.rec-arrow {
  position: absolute;
  width: 60px;
  height: 60px;
  border: 2px solid var(--main-color);
  background-color: inherit;
  box-sizing: border-box;
  color: var(--main-color);
}
.rec.rec-arrow:hover:enabled {
  background-color: var(--main-color);
  color: var(--white-color);
}
.rec.rec-arrow:focus:enabled {
  background-color: var(--main-color);
  color: var(--white-color);
}
.rec.rec-arrow:hover:disabled {
  cursor: initial;
  background-color: inherit;
  border: 2px solid var(--border-card-color);
  color: var(--border-card-color);
}
.rec.rec-arrow-left {
  right: 100px;
  bottom: -100px;
}
.rec.rec-arrow-right {
  right: 20px;
  bottom: -100px;
}
.rec.rec-pagination {
  position: absolute;
  left: 20px;
  bottom: -80px;
}
.rec.rec-dot {
  width: 15px;
  height: 15px;
  background-color: var(--border-card-color);
  border: none;
  box-shadow: none;
  
}
.cqQwrw {
  box-shadow: none !important;
}
.rec.rec-dot:hover {
  box-shadow: 0 0 1px 3px rgb(245 56 56 / 40%);
  box-shadow: none;
}
.rec.rec-dot:focus {
  box-shadow: 0 0 1px 3px rgb(245 56 56 / 40%);
  box-shadow: none;
}
.rec.rec-dot_active {
  width: 40px;
  border-radius: 20px;
  background-color: var(--main-color);
  box-shadow: none !important;
}

@media all and (max-width: 1250px) {
  .slider {
    margin: 60px auto 100px;
    max-width: 1024px;
  }
  .rec.rec-arrow-left {
    right: 140px;
    bottom: -100px;
  }
  .rec.rec-arrow-right {
    right: 60px;
    bottom: -100px;
  }
  .rec.rec-pagination {
    position: absolute;
    left: 60px;
    bottom: -80px;
  }
}

@media all and (max-width: 1000px) {
  .slider__item {
    width: 350px;
    height: 350px;
    padding: 15px;
  }
  .rec.rec-arrow-left {
    right: 100px;
    bottom: -80px;
  }
  .rec.rec-arrow-right {
    right: 20px;
    bottom: -80px;
  }
  .rec.rec-pagination {
    position: absolute;
    left: 20px;
    bottom: -60px;
  }
}

@media all and (max-width: 760px) {
  .slider {
    margin: 20px auto 50px;
    max-width: 1280px;
  }
  .slider__item {
    width: 300px;
    height: 300px;
    padding: 15px;
  }
  .rec.rec-arrow-left {
    display: none;
  }
  .rec.rec-arrow-right {
    display: none;
  }
  .rec.rec-pagination {
    position: relative;
    left: 0px;
    bottom: 0px;
    margin: 10px 0px 0px;
  }
  .rec.rec-carousel-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .slider__item-text {
    margin: 15px 0px 0px 0px;
    font-size: 14px;
    line-height: 1.4;
  }
  .slider__person-rating {
    padding-right: 15px;
  }
  .slider__person-rating::after {
    width: 14px;
    height: 14px;
  }
}