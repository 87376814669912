.advantages {
  width: 100%;
  padding: 150px 0px 0px 0px;
  background-color: var(--background-secondary-color);
  position: relative;
}
.advantages__title {
  margin: 0px;
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 50px;
  color: var(--title-color);
  text-align: center;
}
.advantages__subtitle {
  max-width: 555px;
  margin: 20px auto 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  color: var(--subtitle-color);
  text-align: center;
}
.advantages__list {
  margin: 60px 0px 0px 0px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0px;
  list-style-type: none;
}
.advantages__item {
  margin-bottom: 40px;
  background-color: var(--white-color);
  border: 2px solid var(--border-card-color);
  box-sizing: border-box;
  border-radius: 10px;
  width: 400px;
  height: 400px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .2);
  padding: 40px 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: .3s ease-in-out;
}
.advantages__item:hover {
  border: 2px solid var(--main-color);
}
.advantages__item-img {
  width: 220px;
  height: 220px;
  object-fit: contain;
  object-position: center;
  transition: .3s ease-in-out;
}
.advantages__item:hover .advantages__item-img {
  transform: scale(1.05);
}
.advantages__item-title {
  margin: 30px 0px 0px 0px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: var(--title-color);
}

@media all and (max-width: 1400px) {
  .advantages__item {
    width: 360px;
  }
}

@media all and (max-width: 1250px) {
  .advantages {
    padding: 150px 0px 0px 0px;
  }
  .advantages__title {
    font-size: 28px;
    line-height: 1.3;
  }
  .advantages__subtitle {
    font-size: 14px;
    line-height: 1.15;
  }
  .advantages__list {
    margin: 60px auto 0px;
  }
  .advantages__item {
    width: 300px;
    height: 300px;
    padding: 30px 60px;
  }
  .advantages__item-img {
    width: 150px;
    height: 150px;
  }
  .advantages__item-title {
    margin: 20px 0px 0px 0px;
    font-size: 16px;
    line-height: 20px;
  }
}

@media all and (max-width: 1000px) {
  .advantages__list {
    max-width: 650px;
  }
}

@media all and (max-width: 760px) {
  .advantages {
    padding: 120px 0px 0px 0px;
  }
  .advantages__title {
    font-size: 24px;
    line-height: 1.3;
  }
  .advantages__subtitle {
    margin: 10px 0px 0px 0px;
  }
  .advantages__list {
    margin: 20px 0px 0px 0px;
    justify-content: center;
    align-items: center;
  }
  .advantages__item {
    width: 288px;
    height: 288px;
    margin: 0px 0px 20px 0px;
    padding: 20px 40px;
  }
  .advantages__item-img {
    width: 160px;
    height: 160px;
  }
  .advantages__item-title {
    margin: 10px 0px 0px 0px;
    font-size: 16px;
    line-height: 20px;
  }
}