.popup {
  position: fixed;
  left: 0px;
  top: 0px;
  background-color: rgba(0, 0 , 0, .5);
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-in-out, visibility 0s linear 0.5s;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  z-index: 100;
}
.popup_opened {
  opacity: 1;
  visibility: visible;
  transition-delay: 0s;
}
.popup__container {
  max-height: 95vh;
  margin: 20vh auto 0px;
  padding: 34px 36px 28px;
  max-width: 800px;
  min-height: 114px;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: var(--secondary-color);
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
}
.popup__container::-webkit-scrollbar {
  width: 10px;
  border-radius: 16px;
}
.popup__container::-webkit-scrollbar-thumb {
  background-color: var(--background-third-color);
  outline: none;
  border-radius: 16px;
}
.popup__close-button {
  position: absolute;
  padding: 0;
  right: -45px;
  top: -45px;
  width: 40px;
  height: 40px;
  border: none;
  box-sizing: border-box;
  background-color: transparent;
  background-image: url(../../images/close-button.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  -webkit-background-size: contain;        
  -moz-background-size: contain;          
  -o-background-size: contain;        
  transition: opacity .3s ease-in-out;
  outline: none;
  z-index: -1;
}
.popup__close-button:hover {
  cursor: pointer;
  opacity: .7;
}
.popup__description {
  display: flex;
}
.popup__img {
  width: 250px;
  height: 250px;
  object-fit: contain;
  object-position: center;
  pointer-events: none;
  margin-right: 50px;
}
.popup__figcaption {
  display: flex;
  flex-direction: column;
}
.popup__title {
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 1.15;
  color: var(--white-color);
  margin: 0px 0px 22px 0px;
}
.popup__caption {
  font-style: normal;
  font-size: 18px;
  line-height: 1.4;
  color: var(--white-color);
  margin: 0px 0px 22px 0px;
}
.popup__form {
  display: flex;
  flex-direction: column;
}
.popup__form-line {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: var(--white-color);
  border-radius: 100px;
  margin-bottom: 15px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
}
.popup__input {
  width: 100%;
  justify-content: space-between;
  padding: 15px 30px;
  border-radius: 100px;
  border: none;
  outline: none;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 1.15px;
  color: var(--title-color);
  letter-spacing: 1.2px;
}
.popup__input::placeholder {
  color: var(--subtitle-color);
  font-size: .8em;
  letter-spacing: .5px;
}
.popup__form-line-separator {
  width: 20px;
  height: 35px;
  border-radius: 100px;
  background-color: var(--secondary-color);
}
.popup__form-line-separator_type_error {
  background-color: var(--main-color);
}
.popup__submit-button {
  margin: 15px auto 0px;
  max-width: 350px;
  background-color: var(--main-color);
  color: var(--white-color);
  box-sizing: border-box;
  border: none;
  border-radius: 100px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 1.4px;
  padding: 17.5px 77.5px;
  outline: none;
  transition: all .3s ease-in-out;
  position: relative;
  z-index: 3;
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
}
.popup__submit-button:hover {
  cursor: pointer;
  opacity: .7;
}
.popup__submit-button_type_block {
  pointer-events: none;
  user-select: none;
  background-color: var(--background-main-color);
  color: var(--background-third-color);
}
.popup__submit-button_type_loading {
  pointer-events: none;
  user-select: none;
  background-color: var(--background-main-color);
  color: var(--main-color);
}
.popup__contacts {
  display: flex;
  flex-direction: column;
}
.popup__contacts__title {
  margin: 0px;
  color: var(--white-color);
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
}
.popup__items {
  margin: 10px 0px 0px 0px;
  display: flex;
}
.popup__contacts__item {
  margin: 0px 30px 0px 0px;
  color: var(--white-color);
  padding-left: 30px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 25px;
  position: relative;
}
.popup__contacts__item:last-of-type {
  margin: 0px;
}
.popup__contacts__item_type_email::before {
  position: absolute;
  top: 0px;
  left: 0px;
  content: '';
  width: 25px;
  height: 25px;
  background-image: url('../../images/email.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.popup__contacts__item_type_phone::before {
  position: absolute;
  top: 0px;
  left: 0px;
  content: '';
  width: 25px;
  height: 25px;
  background-image: url('../../images/phone.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

@media all and (max-width: 1400px) {
  .popup__container {
    margin: 15vh auto 0px;
  }
  .popup__img {
    width: 220px;
    height: 220px;
    margin-right: 50px;
  }
  .popup__title {
    font-size: 32px;
    line-height: 1.3;
    margin: 0px 0px 16px 0px;
  }
  .popup__caption {
    font-size: 16px;
    line-height: 20px;
    margin: 0px 0px 16px 0px;
  }
}

@media all and (max-width: 1250px) {
  .popup__container {
    margin: 10vh auto 0px;
  }
  .popup__close-button {
    right: -40px;
    top: -40px;
    width: 30px;
    height: 30px;
  }
}

@media all and (max-width: 1000px) {
  .popup__container {
    margin: 10vh auto 0px;
    max-width: 600px;
    padding: 24px 30px 20px;
  }
  .popup__close-button {
    right: 0px;
    top: -35px;
    
  }
  .popup__img {
    width: 200px;
    height: 200px;
  }
  .popup__title {
    font-size: 24px;
    line-height: 1.15;
  }
  .popup__caption {
    font-size: 14px;
    line-height: 1.3;
    margin: 0px 0px 12px 0px;
  }
  .popup__input {
    padding: 13px 22px;
    font-size: 16px;
  }
  .popup__form-line-separator {
    width: 15px;
    height: 30px;
  }
  .popup__submit-button {
    margin: 20px auto 20px;
    font-size: 16px;
    line-height: 20px;
    padding: 12px 45px;
  }
}

@media all and (max-width: 760px) {
  .popup__container {
    margin: 3vh 16px 0px;
    padding: 12px 16px;
    overflow-y: auto;
  }
  .popup__description {
    flex-direction: column;
    margin: 0px;
  }
  .popup__close-button {
    right: 10px;
    top: 15px;
    z-index: 20;
  }
  .popup__input {
    font-size: 14px;
    line-height: 1.15px;
    padding: 15px;
  }
  .popup__input::placeholder {
    font-size: 12px;
  }
}

@media all and (max-height: 600px) {
  .popup__container {
    max-height: 95vh;
    overflow-y: auto;
    padding: 12px 8px;
    margin: 3vh 8px 0px;
  }
  .popup__container::-webkit-scrollbar {
    width: 10px;
    border-radius: 16px;
  }
  .popup__container::-webkit-scrollbar-thumb {
    background-color: var(--background-third-color);
    outline: none;
    border-radius: 16px;
  }
  .popup__submit-button {
    margin: 10px auto 15px;
  }
}