.footer {
  width: 100%;
  padding: 0px 0px 30px 0px;
  background-color: var(--secondary-color);
  position: relative;
}
.footer__container {
  display: flex;
  justify-content: space-between;
}
.footer__info {
  max-width: 350px;
  display: flex;
  flex-direction: column;
  margin-right: 30px;
}
.footer__logo {
  height: 70px;
  object-position: left;
  object-fit: contain;
}
.footer__caption {
  margin: 20px 0px 0px 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  color: var(--white-color);
}
.footer__caption_font_bold {
  font-weight: bold;
}
.footer__social {
  margin: 30px 0px;
  padding: 0px;
  list-style-type: none;
  display: flex;
}
.footer__social-link {
  margin-right: 20px;
}
.footer__social-link:last-of-type {
  margin-right: 0px;
}
.footer__social-img {
  width: 34px;
  height: 34px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
  border-radius: 50%;
  background-color: var(--white-color);
  object-fit: contain;
  object-position: center;
  transition: .3s ease-in-out;
}
.footer__social-img:hover {
  cursor: pointer;
  transform: scale(1.1);
}
.footer__copy {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: var(--white-color);
}
.footer__columns {
  padding: 0px;
  margin: 0px;
  list-style-type: none;
  display: flex;
}
.footer__column {
  margin-right: 126px;
}
.footer__column:last-of-type {
  margin-right: 0px;
}
.footer__column-title {
  margin: 0px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 30px;
  color: var(--white-color);
}
.footer__column-links {
  margin: 20px 0px 0px 0px;
  padding: 0px;
  list-style-type: none;
}
.footer__column-item {
  margin: 0px 0px 10px 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  color: var(--white-color);
}
.footer__column-link {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  color: var(--white-color);
  transition: .3s ease-in-out;
  text-decoration: none;
}
.footer__column-link:last-of-type {
  margin: 0px;
}
.footer__column-link:hover {
  color: var(--main-color);
  cursor: pointer;
}

@media all and (max-width: 1250px) {
  .footer__logo {
    height: 60px;
  }
  .footer__caption {
    font-size: 14px;
    line-height: 1.15;
  }
  .footer__copy {
    font-size: 14px;
    line-height: 18px;
  }
  .footer__column {
    margin-right: 86px;
  }
  .footer__column-title {
    font-size: 16px;
    line-height: 20px;
  }
  .footer__column-links {
    margin: 20px 0px 0px 0px;
  }
  .footer__column-item {
    margin: 0px 0px 10px 0px;
    font-size: 14px;
    line-height: 18px;
  }
  .footer__column-link {
    font-size: 14px;
    line-height: 18px;
  }
}

@media all and (max-width: 1000px) {
  .footer__column {
    margin-right: 40px;
  }
  .footer__info {
    margin-right: 50px;
  }
}

@media all and (max-width: 760px) {
  .footer__container {
    display: flex;
    flex-direction: column-reverse;
  }
  .footer__columns {
    display: flex;
    flex-direction: column;
  }
  .footer__wave {
    height: 50px;
    top: -50px;
  }
  .footer__column-links {
    margin: 7px 0px 0px 0px;
  }
  .footer__column {
    margin-right: 0px;
    margin-bottom: 15px;
  }
  .footer__social {
    margin: 20px 0px 10px;
  }
  .footer__caption {
    margin: 10px 0px 0px 0px;
  }
  .footer__column-title {
    font-size: 18px;
    line-height: 24px;
  }
  .footer__column-item {
    margin: 0px 0px 10px 0px;
    font-size: 16px;
    line-height: 20px;
  }
  .footer__column-link {
    font-size: 16px;
    line-height: 20px;
  }
  .footer__caption {
    font-size: 16px;
    line-height: 20px;
  }
  .footer__info {
    margin-right: 0px;
  }
}