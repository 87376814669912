.about {
  margin: 100px auto 0px;
  padding: 200px 0px 0px 0px;
  width: 100%;
}
.about__container {
  background-color: var(--secondary-color);
  position: relative;
  padding-bottom: 50px;
}
.about__flex-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.about__img-container {
  position: relative;
  margin: -180px 0px 0px 0px;
}
.about__img {
  width: 520px;
  height: 520px;
  object-fit: contain;
  object-position: center;
  pointer-events: none;
  z-index: 3;
}
.about__img-container::after {
  content: url('../../images/about-dots.png');
  transform: scale(.08);
  position: absolute;
  overflow: hidden;
  bottom: -1230px;
  left: -600px;
  color: white;
  z-index: 3;
}
.about__info {
  max-width: 600px;
  margin-top: -50px;
  padding-top: 50px;
  z-index: 3;
}
.about__title {
  margin: 0px;
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 50px;
  color: var(--white-color);
}
.about__subtitle {
  margin: 20px 0px 0px 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  color: var(--white-color);
}
.about__subtitle_weight_bold {
  font-weight: bold;
}
.wave-about {
  position: absolute;
  top: -150px;
  left: 0px;
  height: 150px;
  width: 100%;
  overflow: hidden;
}

@media all and (max-width: 1250px) {
  .about__img-container {
    position: relative;
    margin: -120px 0px 0px 0px;
  }
  .about__img {
    margin-left: 30px;
    width: 400px;
    height: 400px;
  }
  .about__info {
    max-width: 380px;
    margin-top: 0px;
    margin-right: 70px;
    padding-top: 0px;
  }
  .about__title {
    font-size: 24px;
    line-height: 1.4;
  }
  .about__subtitle {
    margin: 20px 0px 0px 0px;
    font-size: 12px;
    line-height: 15px;
  }
}

@media all and (max-width: 1000px) {
  .about__img-container {
    margin: -200px 0px 0px 0px;
  }
  .about__img {
    margin: 0px;
    width: 300px;
    height: 300px;
  }
  .about__info {
    max-width: 360px;
    margin: -40px 30px 0px 0px;
  }
  .about__title {
    font-size: 20px;
    line-height: 1.3;
  }
  .about__subtitle {
    margin: 20px 0px 0px 0px;
    font-size: 14px;
    line-height: 18px;
  }
  .about__img-container::after {
    bottom: -1230px;
    left: -640px;
  }
}

@media all and (max-width: 760px) {
  .about {
    margin: 0px auto;
  }
  .about__container {
    padding-bottom: 20px;
  }
  .about__flex-container {
    flex-direction: column;
  }
  .about__img-container {
    margin: -120px 0px 0px 0px;
  }
  .about__info {
    margin: 0px;
  }
  .about__subtitle {
    margin: 15px 0px 0px 0px;
  }
  .about__img {
    margin: 0px;
  }
  .about__img-container::after {
    bottom: -1100px;
    left: -670px;
  }
}